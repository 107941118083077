<template>
	<div></div>
</template>

<script>
	export default{
		data(){
			return {
				
			}
		},
		beforeCreate(){
			this.$router.push(this.$route.query.path);
		}
	}
</script>